var hostUrl = '';
//et sessionExpireTime = 3000; //登录默认cookie存储3000秒，服务端默认是一小时
let sessionExpireTime = 1* 3600; //登录默认cookie改为1小时，服务端每次请求都会返回新的tckw-cookie
let authExpireTime = 24*7*3600;
let encodeKey = "dR0NQgK5THcQSLdq";
switch (process.env.NODE_ENV) {
    case 'development':
        //hostUrl = 'http://localhost:8088';
        // hostUrl = 'https://api.wireropeinspection.cn:8088';
        hostUrl = 'http://47.254.89.27:8088/';
        break;
    case 'production':
        hostUrl = 'http://47.254.89.27:8088/';
        break;
    default:
        break;
}

export default {
    hostUrl,
    sessionExpireTime,
    authExpireTime,
    encodeKey
}
