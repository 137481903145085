 import axios from 'axios'
 //  import XLSX from "xlsx";
 //  import {
 //    Base64
 //  } from 'js-base64';
 import AES from "@/common/AES.js";
 import md5 from 'js-md5';
 import VueCookies from 'vue-cookies'
 import env from '../env.js'
 let encodeKey = env.encodeKey; //AES 秘钥
 let expireTime = env.expireTime; //cookie过期时间

 var random = function (min, max) {

   return Math.floor(Math.random() * (max - min)) + min;
 }


 function getDay(num, str) {
   var today = new Date();
   var nowTime = today.getTime();
   var ms = 24 * 3600 * 1000 * num;
   today.setTime(parseInt(nowTime + ms));
   var oYear = today.getFullYear();
   var oMoth = (today.getMonth() + 1).toString();
   if (oMoth.length <= 1) oMoth = '0' + oMoth;
   var oDay = today.getDate().toString();
   if (oDay.length <= 1) oDay = '0' + oDay;
   return oYear + str + oMoth + str + oDay;
 }


 // 格式化日期
 function formatDate(date) {
   date = new Date();
   let myyear = date.getFullYear();
   let mymonth = date.getMonth() + 1;
   let myweekday = date.getDate();
   mymonth < 10 ? mymonth = "0" + mymonth : mymonth;
   myweekday < 10 ? myweekday = "0" + myweekday : myweekday;
   return `${myyear}-${mymonth}-${myweekday}`;
 }


 function formatDateTime() {
   let date = new Date();
   let myyear = date.getFullYear();
   let mymonth = date.getMonth() + 1;
   let myweekday = date.getDate();
   let myhours = date.getHours();
   let myminutes = date.getMinutes();
   mymonth < 10 ? mymonth = "0" + mymonth : mymonth;
   myweekday < 10 ? myweekday = "0" + myweekday : myweekday;
   // myhours < 10 ? myhours = "0" + myhours : myhours;
   // myminutes < 10 ? myminutes = "0" + myminutes : myminutes;
   return `${myyear}${mymonth}${myweekday}${myhours}${myminutes}`;
 }

 // 获取当前月的天数
 function mGetDate() {
   var date = new Date();
   var year = date.getFullYear();
   var month = date.getMonth() + 1;
   var d = new Date(year, month, 0);
   return d.getDate();
 }
 // 获取本周的日期
 function getWeekDay() {
   let dateString = formatDate(new Date()); //当天的日期，例如2020-2-28
   let presentDate = new Date(dateString);
   let today = presentDate.getDay() !== 0 ? presentDate.getDay() : 7;
   return Array.from(new Array(7), function (val, index) {
     return formatDate(new Date(presentDate.getTime() - (today - index - 1) * 24 * 60 * 60 * 1000));
   });
 }
 // 获取当前月的所有日期
 function getNowM() {
   let now = new Date();
   let current_month_num = mGetDate();
   let current_month = [];
   for (let i = 1; i <= current_month_num; i++) {
     let day = now.setDate(i);
     let everyDay = formatDate(day);
     current_month.push(everyDay);
   }
   return current_month;
 }

 function getWeekDays(getTime = '') {
   if (getTime == '') {
     getTime = new Date().getTime();
   }
   var currentDate = new Date(getTime);
   var timesStamp = currentDate.getTime();
   var currenDay = currentDate.getDay();
   console.log(currenDay)
   var dates = [];
   for (var i = 0; i < 7; i++) {

     dates.push(
       new Date(
         timesStamp + 24 * 60 * 60 * 1000 * (i - ((currenDay + 6) % 7))
       )
       .toLocaleDateString()
       .replace(/\//g, "-").substr(5)
     );
   }
   return dates;
 }


 //重新获取新的session
 var getNewTckwCookie = function () {
   let timeStamp = parseInt(new Date().getTime() / 1000);

   let md5Pwd = "";
   let username = "";
   if (VueCookies.get('password')) {
     md5Pwd = md5("TCK.W" + AES.decrypt(VueCookies.get('password'), encodeKey) + timeStamp);
     username = AES.decrypt(VueCookies.get('username'), encodeKey);
   }

   if (md5Pwd) {
     var data = {
       time_stamp: timeStamp,
       user_name: username,
       user_pwd: md5Pwd,
     };
     return axios.post("/api/Login", JSON.stringify(data), {
       withCredentials: true,
     }).then((res) => {
       var tckw_cookie = res.headers["tckw-cookie"];
       VueCookies.set("TCKWSESSID", tckw_cookie, expireTime);
       VueCookies.set("timeStamp", timeStamp, expireTime);
       return tckw_cookie;
     })
   }

   return null;
 }

 var checkSessionIdExpire = function () {
   if (!VueCookies.get("TCKWSESSID")) {
     getNewTckwCookie();
   }

 }


 var getFunDate = function fun_date(currentDay, targetDay) {
   currentDay = currentDay == '' ? new Date() : currentDay;
   //time1=date1.getFullYear()+"-"+(date1.getMonth()+1)+"-"+date1.getDate();//time1表示当前时间  
   var date2 = new Date(currentDay);
   date2.setDate(date2.getDate() + targetDay);
   return date2.getFullYear() + "-" + (date2.getMonth() + 1) + "-" + date2.getDate();

 }

 // 随机生成32位字符串
 var randomString = function randomString(len = 32) {
   let $chars =
     'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
   let maxPos = $chars.length
   let pwd = ''
   for (let i = 0; i < len; i++) {
     pwd += $chars.charAt(Math.floor(Math.random() * maxPos))
   }
   return pwd
 }

 // function expertData() {
 //   let tableData = [["姓名", "年龄", "性别", "部门/小组"]]; // 表格表头
 //   this.testData.forEach((item) => {
 //     let rowData = [];
 //     rowData = [item.name, item.age, item.sex, item.department];
 //     tableData.push(rowData);
 //   });
 //   let ws = XLSX.utils.aoa_to_sheet(tableData);
 //   let wb = XLSX.utils.book_new();
 //   XLSX.utils.book_append_sheet(wb, ws, "数据"); // 工作簿名称
 //   XLSX.writeFile(wb, "数据.xlsx"); // 保存的文件名
 // }

 //currentDate  当前日期
 //targetDate  目标日期
 function GetNumberOfDays(currentDate, targetDate) { //获得天数
   //date1：开始日期，date2结束日期
   var a1 = Date.parse(new Date(currentDate));
   var a2 = Date.parse(new Date(targetDate));
   var day = parseInt((a2 - a1) / (1000 * 60 * 60 * 24)); //核心：时间戳相减，然后除以天数
   return day
 }

 function getDate(datestr) {
   let temp = datestr.split('-')
   let date = new Date(temp[0], temp[1] - 1, temp[2])
   return date
 }


 // 2.获取时间段内的所有日期 Spacer:间隔符
 function formatEveryDay(start, end, Spacer = "-") {
   //let weekArr = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
   let dateList = []
   let startTime = getDate(start)
   let endTime = getDate(end)

   while ((endTime.getTime() - startTime.getTime()) >= 0) {
     let year = startTime.getFullYear()
     let month = startTime.getMonth() + 1 < 10 ? '0' + (startTime.getMonth() + 1) : startTime.getMonth() + 1
     let day = startTime.getDate().toString().length == 1 ? '0' + startTime.getDate() : startTime.getDate()
     //let timeDay = startTime.getDay(startTime)
     // dateList.push({
     //   time: year + '-' + month + '-' + day,
     //   week: weekArr[startTime.getDay(startTime)],
     //   isWeekend: timeDay === 0 || timeDay === 6
     // })

     if (Spacer) {
       // 如果不需要间隔符则直接拼接返回
       if (Spacer === 'null') {
         dateList.push(year + month + day) // 如20220422,20220423
       } else {
         // 需要自定义间隔符
         dateList.push(year + Spacer + month + Spacer + day)
       }
     } else {
       dateList.push(year + '-' + month + '-' + day) // 2022-04-22,2022-04-23
     }
     startTime.setDate(startTime.getDate() + 1)
   }
   return dateList
 }


 // function GetNumberOfDays(date1,date2){//获得天数
 //   //date1：开始日期，date2结束日期
 //   var a1 =new Date(date1).getTime();
 //   var a2 =new Date(date2).getTime();
 //   var day = parseInt((a2-a1)/ (1000 * 60 * 60 * 24));//核心：时间戳相减，然后除以天数
 //   return day
 // }

 
 export default {
   random,
   getNowM,
   getDay,
   getWeekDay,
   mGetDate,
   getNewTckwCookie,
   checkSessionIdExpire,
   getWeekDays,
   getFunDate,
   randomString,
   GetNumberOfDays,
   formatDate,
   formatDateTime,
   formatEveryDay
 }