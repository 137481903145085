<template>
  <div class="container">
    <div class="header">
      <div class="logo-box" @click="jumpToHome">
        <!-- <img src="@/assets/logo.png" alt="" /> -->
        <img :src="logoPath" alt="" />
      </div>
      <div class="title-box" @click="saveSessionAuth">
        <p :class="[titleFontSize]">{{ langObj.login.title }}</p>
      </div>
      <div class="version-box">
        <p>v5.0.230401</p>
        <p class="lang"><lang /></p>
        <p>
          <el-button
            @click="loginOutSystem"
            style="margin-left: 10px"
            type="success"
            size=""
            >退出</el-button
          >
        </p>
        <p v-show="is_update">
          <el-button
            @click="downloadSoft"
            style="margin-left: 10px"
            type="primary"
            >升级</el-button
          >
        </p>
      </div>
    </div>

    <div class="zhuangshi">
      <div class="left">
        <dv-decoration-1 style="width: 3.75rem; .375rem" />
      </div>
      <div class="center">
        <dv-decoration-5 duration="3" style="width: 8rem; height: 30px" />
      </div>
      <div class="right">
        <dv-decoration-1 :reverse="true" style="width: 3.75rem; .375rem" />
      </div>
      <div class="line"></div>
    </div>

    <div class="alarm-dialog">
      <el-dialog
        v-model="dialogSoftVisible"
        title="软件下载"
        width="70%"
        :close-on-click-modal="false"
      >
        <softwire></softwire>
      </el-dialog>
    </div>
  </div>
</template>

<script setup>
import { nextTick, onMounted, computed, watch, ref } from "vue";
import VueCookies from "vue-cookies";
import { ElMessage, ElMessageBox } from "element-plus";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import lang from "@/components/header/lang.vue";
import softwire from "@/components/header/softwire/softwire.vue";
let router = useRouter();
let dialogSoftVisible = ref(false);
// const hostUrl =
//   getCurrentInstance().appContext.config.globalProperties.$hostUrl; // vue3不能从prototype获取
let titleFontSize = ref(""); //动态设置标题尺寸
//定义logo路径的变量 从配置项获取
let logoPath =
  window.config.is_tckw == 1 ? "/config/logo_tckw.png" : "/config/logo_cus.png";
let is_update = window.config.is_update == 1 ? ref(true) : ref(false);
const clearLoginCookie = () => {
  VueCookies.remove("TCKWSESSID");
  VueCookies.remove("auth");
  VueCookies.remove("timeStamp");
};

const jumpToHome = () => {
  router.push(`/`);
};

const store = useStore();

const langObj = computed(() => {
  return store.state.lang.list;
});

watch(
  () => store.state.lang,
  ({ lang }) => {
    if (lang == "zh-CN") {
      titleFontSize.value = "cn";
    } else if (lang == "en") {
      titleFontSize.value = "en";
    }
  },
  { deep: true, immediate: true }
);

const saveSessionAuth = () => {
  let num = store.state.auth;
  store.commit("saveStationAuth", ++num); //存储到vue内部当中后面每个接口都要从内存获取
};

const loginOutSystem = () => {
  ElMessageBox.confirm("确定要退出吗", "warning", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "确定要删除吗",
  })
    .then(() => {
      clearLoginCookie();
      ElMessage({
        message: "退出成功",
        type: "success",
        offset: 150,
        duration: 1000,
        onClose: () => {},
      });
      router.push({
        name: "login",
      });
    })
    .catch(() => {
      ElMessage({
        type: "info",
        duration: 2000,
        message: "已取消操作",
      });
    });
};

const downloadSoft = () => {
  dialogSoftVisible.value = true;
};

onMounted(async () => {
  nextTick(() => {
    console.log(window.config);
  });
});
</script>

<style lang="scss" scoped>
.container {
  .header {
    color: #fff;
    height: 0.625rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    position: relative;
    .logo-box {
      margin-top: 0.25rem;
      width: 2.25rem;
      img {
        width: 100%;
      }
    }
    .title-box {
      position: absolute;
      left: 50%;
      top: 0.15rem;

      transform: translateX(-50%);

      p {
        font-size: 0.5rem;
        color: #fff;
        letter-spacing: 0.0375rem;
        text-align: center;
        font-weight: 600;
        margin-top: 0.0625rem;
      }
      .cn {
        font-size: 0.5rem;
      }
      .en {
        font-size: 0.375rem;
      }
    }
    .version-box {
      width: 3.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right: 0.25rem;
      p {
        font-size: 0.3125rem;
      }
      .lang {
        margin: 0 5px;
        position: relative;
        top: 0.0375rem;
      }
    }
  }
  .zhuangshi {
    padding-top: 0.125rem;
    display: flex;
    justify-content: space-between;
    position: relative;
    .line {
      position: absolute;
      bottom: -5px;
      height: 1px;
      // border-bottom: 1px solid rgb(63, 150, 165);
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
    }
  }

  & :deep .el-dialog {
    background-color: #021e33;
  }
  & :deep .el-dialog__header {
    text-align: center;
  }
  & :deep .el-dialog__header span {
    color: #fff;
  }
}
</style>
